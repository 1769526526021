import { Component, ContentChild, ElementRef, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { ButtonSeverity, ButtonSize, ButtonVariant, IconPosition } from './button.types';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { IconPackageKey, IconSize, IconAnimation, IconRotation, IconFlip } from '../icon/icon.types';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [IconComponent],
  styles: [``],
  template: `
    <button
      [type]="type"
      [disabled]="disabled"
      [attr.aria-disabled]="disabled"
      [attr.icon]="iconPosition"
      [attr.rounded]="rounded"
      [attr.url]="isUrl"
      [attr.tag]="isTag"
      [attr.mode]="responsiveMode ? 'responsive' : null"
      [attr.responsive-breakpoint]="responsiveBreakpoint"
      [attr.size]="size"
      [attr.background]="background ? null : 'none'"
      [attr.line]="line"
      [attr.auto-height]="autoHeight"
      [attr.text-align]="textAlign"
      [class]="allClasses"
    >
      <ng-content select="[content]"></ng-content>
      @if (!hasProjectedContent()) { @if (text) {
      <span>{{ text }}</span>
      } @if (iconName) {
      <app-icon
        [name]="iconName"
        [package]="iconPackage"
        [size]="iconSize"
        [animation]="iconAnimation"
        [rotation]="iconRotation"
        [flip]="iconFlip"
        [class]="iconClass"
        [fixedWidth]="iconFixedWidth"
      ></app-icon>
      } }
    </button>
  `,
})
export class ButtonComponent {
  @Input() text?: string;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() disabled = false;

  // Button-specific inputs
  @Input() variant: ButtonVariant = 'full';
  @Input() severity: ButtonSeverity = 'primary';
  @Input() size: ButtonSize = 'medium';
  @Input() iconPosition?: IconPosition;
  @Input() rounded = false;
  @Input() isUrl = false;
  @Input() isTag = false;
  @Input() responsiveMode = false;
  @Input() responsiveBreakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'sm';
  @Input() background = true;
  @Input() line = false;
  @Input() autoHeight = false;
  @Input() textAlign: 'left' | 'center' | 'right' = 'center';
  @Input() class = '';

  // Icon-specific inputs
  @Input() iconName?: IconName | IconName[];
  @Input() iconPackage: IconPackageKey | IconPackageKey[] = 'regular';
  @Input() iconSize: IconSize | IconSize[] = 'none';
  @Input() iconAnimation: IconAnimation | IconAnimation[] = 'none';
  @Input() iconRotation: IconRotation | IconRotation[] = 'none';
  @Input() iconFlip: IconFlip | IconFlip[] = 'none';
  @Input() iconClass = '';
  @Input() iconFixedWidth = false;

  @ContentChild('content') projectedContent!: ElementRef<HTMLElement>;
  
  hasProjectedContent(): boolean {
    return !!this.projectedContent;
  }

  get hostClasses(): string {
    return `button-${this.variant}-${this.severity}`;
  }

  get allClasses(): string {
    return [this.hostClasses, this.class].filter(Boolean).join(' ').trim();
  }
}
